/* eslint-disable no-console */
/* Keep console log entries while confirming ENG-325 fix */
const NEEDS_REFRESH = 'NEEDS_REFRESH';
const NEEDS_LOGIN = 'NEEDS_LOGIN';
const VALID = 'VALID';
const OFFLINE = 'OFFLINE';

function isAuthValid(state) {
  const { auth } = state;
  const conn = state.main.connection;
  if (auth && auth.tokens && auth.tokens.access_token && auth.tokens.expires_in) {
    if (conn.online === false) {
      // The user is offline
      // Allow using as long as we have the basic info
      if (!!auth.firstName && !!auth.lastName && !!auth.userID) {
        return OFFLINE;
      }
    }
    const now = new Date();
    if (parseInt(auth.tokens.expires_in, 10) >= now.getTime()) {
      return VALID;
    }
  }
  if (auth && auth.tokens && auth.tokens.refresh_failure) {
    return NEEDS_LOGIN;
  }
  if (auth && auth.tokens && auth.tokens.refresh_token) {
    return NEEDS_REFRESH;
  }
  return NEEDS_LOGIN;
}

function isLoggedOut(slice) {
  return (
    slice.tokens.access_token === null &&
    slice.tokens.refresh_token === null &&
    slice.tokens.id_token === null &&
    slice.tokens.expiry === null
  );
}

// eslint-disable-next-line
export { isAuthValid, isLoggedOut, NEEDS_REFRESH, NEEDS_LOGIN, VALID, OFFLINE };
