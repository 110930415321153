import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { withTranslation, Trans } from 'react-i18next';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TgLogo from 'Components/Library/TgLogo';
import { logout } from 'config/helpers';

const styles = theme => ({
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.app,
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  loginBox: {
    margin: 'auto',
    width: '400px',
  },
});

const stay = () => {
  const { origin, pathname, hash } = window.location;
  const redirectUrl = `${origin}${pathname}${hash}`;
  window.location.assign(redirectUrl);
};

class TenantSwitchDialog extends Component {
  componentWillUnmount() {
    clearTimeout(this.refreshtimer);
  }

  logout = () => {
    const { targetTenant } = this.props;
    logout(this.props.auth, targetTenant);
  };

  render() {
    const { t, classes, targetTenant, currentTenant } = this.props;
    return (
      <div className={classes.backdrop}>
        <div className={classes.wrapper}>
          <div className={classes.loginBox}>
            <Card elevation={24}>
              <Box sx={{ p: 4, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <TgLogo />
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 4,
                    mb: 0,
                    pb: 0,
                    alignSelf: 'stretch',
                  }}
                >
                  <Typography
                    align="center"
                    variant="subtitle2"
                    sx={{ mb: 2 }}
                    color="text.disabled"
                  >
                    <Trans
                      i18nKey="general.switchTenantScreenTitle"
                      values={{
                        targetTenant,
                        currentTenant,
                      }}
                      components={[<b>placeholder</b>, <b>placeholder</b>]}
                    />
                  </Typography>
                </Box>
                <CardActions>
                  <Button id="switch-tenant-stay" onClick={() => stay()}>
                    {t('general.switchTenantScreenStayCTA')}
                  </Button>
                  <Button
                    id="switch-tenant-switch"
                    variant="contained"
                    onClick={() => this.logout()}
                    color="secondary"
                  >
                    {t('general.switchTenantScreenSwitchCTA')}
                  </Button>
                </CardActions>
              </Box>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

TenantSwitchDialog.propTypes = {
  targetTenant: PropTypes.string,
  currentTenant: PropTypes.string,
  classes: PropTypes.exact({
    backdrop: PropTypes.string,
    wrapper: PropTypes.string,
    loginBox: PropTypes.string,
  }),
  auth: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(TenantSwitchDialog));
