import React from 'react';
import PropTypes from 'prop-types';
import StandardDialog from 'Components/Library/StandardDialog';
import ObjectivesTableOverviewHeader from 'Components/Features/Objectives/ObjectivesTableOverviewHeader';
import DomainObjectivesFetchWrapper from 'Components/Features/Objectives/DomainObjectivesFetchWrapper';
import NotFoundPanelContent from 'Components/Common/NotFoundPanelContent';

class DialogObjectives extends React.Component {
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const {
      open,
      onExpand,
      period,
      domain,
      objectivePeriodConfig,
      isValidTeam,
      zIndexModifier = 0,
    } = this.props;

    if (!domain) {
      return null;
    }
    let content;
    if (domain.t === 'team' && !isValidTeam) {
      content = <NotFoundPanelContent />;
    } else {
      content = objectivePeriodConfig.ok && (
        <DomainObjectivesFetchWrapper
          domain={domain}
          period={period}
          render={(objectives, hash) => (
            <ObjectivesTableOverviewHeader
              period={period}
              domain={domain}
              objectiveIDs={objectives}
              hash={hash}
              open
              onClose={this.onClose}
              onExpand={onExpand}
              showControls
              alwaysOpen
            />
          )}
        />
      );
    }

    return (
      <StandardDialog
        open={open}
        name="dialog-team-objectives"
        onBackdropClick={this.onClose}
        disablePortal
        zIndexModifier={zIndexModifier}
        size="large"
      >
        {content}
      </StandardDialog>
    );
  }
}

DialogObjectives.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExpand: PropTypes.func,
  period: PropTypes.string,
  domain: PropTypes.object,
  objectivePeriodConfig: PropTypes.object,
  isValidTeam: PropTypes.bool,
  zIndexModifier: PropTypes.number,
};

export default DialogObjectives;
