import { PURGE } from 'redux-persist';
import { validatePersistedState } from 'state/helpers';
import { isEnabled } from 'config/flags';
import { SET_ATTRIBUTE } from './types';

// initial value for the darkmode is based on user's browser/os setting value, use in initialState once is taken into use and not behind a feature flag
const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;

export const initialState = {
  VERSION: 1.05,
  locale: null,
  darkmode: isEnabled('DARKMODE') && isBrowserDefaultDark,
  menustate: true,
};

const doLogout = (state, action) => {
  const newState = JSON.parse(JSON.stringify(initialState));
  if (action?.payload?.isSwitching) {
    // the user is doing a tenant switch, keep visual settings
    newState.darkmode = state.darkmode;
  }
  return newState;
};

const setAttr = (state, action) => {
  const newState = { ...state };
  if (Object.prototype.hasOwnProperty.call(initialState, action.payload.name)) {
    newState[action.payload.name] = action.payload.value;
  }
  return newState;
};

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case SET_ATTRIBUTE:
      return setAttr(state, action);
    case 'LOGOUT':
    case PURGE:
      return doLogout(state, action);
    default:
      return state;
  }
};
