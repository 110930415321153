import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import StandardContentDialog from 'Components/Library/StandardContentDialog';
import TaskItemDialogContent from 'Components/Library/TaskItemDialogContent';
import DataObjectChip from 'Components/Library/DataObjectChip';
import ActivityLog from 'Components/Library/ActivityLog';
import ObjectivesIcon from 'Components/Features/Objectives/ObjectivesIcon';
import DialogConfirmDelete from 'Components/Features/Objectives/DialogConfirmDelete';
import DialogConfirmCopy from 'Components/Features/Objectives/DialogConfirmCopy';

import { EVENT_TYPE_KREVENT } from 'config/constants';
import NodeRelations from 'Components/Library/NodeRelations/new';

import { graphActions } from 'state/ducks/graph/index';
import ObjectiveDetails from './ObjectiveDetails';
import ObjectiveHeadline from './ObjectiveHeadline';
import ObjectiveRelations from './ObjectiveRelations';
import ObjectiveReflections from './ObjectiveReflections';
import KeyresultsList from './KeyresultsList';
import KeyresultEvent from '../PanelViewKeyresult/KeyresultEventList/KeyresultEvent';

class PanelViewObjective extends React.Component {
  state = {
    deleteDialogOpen: false,
    periodCopyDialogOpen: false,
  };

  togglePeriodCopyDialog = open => {
    this.setState({ periodCopyDialogOpen: open });
  };

  toggleDeleteDialog = deleteOpen => {
    this.setState({ deleteDialogOpen: deleteOpen });
  };

  renderContent() {
    const { objectiveData, objectiveID, canEdit, dispatch, period } = this.props;

    return (
      <TaskItemDialogContent
        loading={!objectiveData.ok}
        headlineComponent={
          <ObjectiveHeadline
            objectiveData={objectiveData}
            objectiveID={objectiveID}
            canEdit={canEdit}
          />
        }
        detailsComponent={
          <>
            <ObjectiveDetails
              objectiveData={objectiveData}
              objectiveID={objectiveID}
              canEdit={canEdit}
            />
            <KeyresultsList
              keyresults={objectiveData?.data?.keyresults}
              objectiveData={objectiveData}
              objectiveID={objectiveID}
              canEdit={canEdit}
            />
            <ObjectiveReflections
              objectiveData={objectiveData}
              objectiveID={objectiveID}
              canEdit={canEdit}
            />
          </>
        }
        relationsComponent={
          <>
            <ObjectiveRelations
              objectiveData={objectiveData}
              objectiveID={objectiveID}
              period={period}
              canEdit={canEdit}
            />
            <br />
            {!!objectiveData?.data?.graph_id && (
              <NodeRelations
                graphId={objectiveData.data.graph_id}
                onSelect={data => {
                  dispatch(
                    graphActions.editRelations({
                      changeset: [
                        {
                          action: 'ADD',
                          from: objectiveData?.data?.graph_id,
                          to: data.node_id,
                          type: 'GENREL',
                        },
                      ],
                    }),
                  );
                }}
                canEdit={canEdit}
              />
            )}
          </>
        }
        logComponent={
          !!objectiveData && (
            <ActivityLog
              nodeId={objectiveData?.data?.graph_id}
              events={get(objectiveData, 'data.eventList', [])}
              elementTypeOverrides={{ [EVENT_TYPE_KREVENT]: KeyresultEvent }}
            />
          )
        }
      />
    );
  }

  render() {
    const {
      onClose,
      open,
      t,
      objectiveData,
      canEdit,
      objectiveID,
      zIndexModifier = 0,
    } = this.props;
    const { deleteDialogOpen, periodCopyDialogOpen } = this.state;

    let menuItems;

    if (!!canEdit) {
      menuItems = [];
      menuItems.push(
        <MenuItem
          id="objective-actions-menu-delete-option"
          onClick={() => this.toggleDeleteDialog(true)}
        >
          {t('general.delete')}
        </MenuItem>,
      );
      if (get(objectiveData, 'data.type') !== 'company') {
        menuItems.push(
          <MenuItem
            id="objective-actions-menu-copy-cadence-option"
            onClick={() => this.togglePeriodCopyDialog(true)}
          >
            {t('objectives.continueobjectivecta')}
          </MenuItem>,
        );
      }
    }
    return (
      <>
        <StandardContentDialog
          size="large"
          open={open}
          onBackdropClick={onClose}
          onCloseClick={onClose}
          menuButtonProps={{
            id: 'objective-actions-menu-toggle',
          }}
          closeButtonProps={{
            id: 'panel-view-objective-close-btn',
          }}
          menuItems={menuItems}
          title={
            <DataObjectChip
              Icon={ObjectivesIcon}
              text={t('objectives.genericDataObjectNameObjective')}
              variant="normal"
            />
          }
          zIndexModifier={zIndexModifier}
        >
          {this.renderContent()}
        </StandardContentDialog>
        {!!periodCopyDialogOpen && (
          <DialogConfirmCopy
            open
            onCancel={() => this.togglePeriodCopyDialog(false)}
            objectiveID={objectiveID}
            objectiveData={objectiveData.data}
          />
        )}
        {!!deleteDialogOpen && (
          <DialogConfirmDelete
            open
            onCancel={() => this.toggleDeleteDialog(false)}
            onConfirm={() => this.toggleDeleteDialog(false)}
            objective={objectiveData.data}
          />
        )}
      </>
    );
  }
}

PanelViewObjective.propTypes = {
  onClose: PropTypes.func,
  canEdit: PropTypes.bool,
  objectiveData: PropTypes.object,
  objectiveID: PropTypes.string,
  period: PropTypes.string,
  open: PropTypes.bool,
  t: PropTypes.func,
  zIndexModifier: PropTypes.number,
  dispatch: PropTypes.func,
};

export default withTranslation()(connect()(PanelViewObjective));
