import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { withNavigation } from 'withRouter';

import { channel } from 'config/swbus';
import { authActions, authSelectors } from 'state/ducks/auth';
import TgProgress from 'Components/Common/TgProgress';
import TgLogo from 'Components/Library/TgLogo';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    margin: 0,
    backgroundColor: theme.palette.background.app,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  loginBox: {
    margin: 'auto',
    width: '400px',
  },
});

class LoggingOut extends Component {
  componentDidMount() {
    channel.postMessage({ title: 'TOKEN_UPDATE', token: 'LOGOUT' });
    this.props.dispatch(
      authActions.revokeTokens({ isSwitching: localStorage.getItem('nextTenant') }),
    );
  }

  componentDidUpdate() {
    const isLoggedOut = authSelectors.isLoggedOut(this.props.auth);
    if (isLoggedOut) {
      this.refreshtimer = setTimeout(() => {
        this.props.navigate('/loggedout');
      }, 1000 * 2);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.refreshtimer);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.loginBox}>
            <Card elevation={24}>
              <CardContent
                sx={{ p: 5, alignItems: 'center', display: 'flex', flexDirection: 'column' }}
              >
                <TgLogo />
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 4,
                    mb: 4,
                  }}
                >
                  <TgProgress />
                </Box>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

LoggingOut.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    wrapper: PropTypes.string,
    loginBox: PropTypes.string,
  }),
  auth: PropTypes.object,
  dispatch: PropTypes.func,
  navigate: PropTypes.func,
};

export default withNavigation(withStyles(styles)(connect(mapStateToProps)(LoggingOut)));
