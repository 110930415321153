import moment from 'moment';
import { PURGE } from 'redux-persist';
import { orderBy, isEmpty, get } from 'lodash';
import { nanoid } from 'nanoid';
import { validatePersistedState, enrich, apiError, fetching, copyState } from 'state/helpers';
import { EVENT_TYPE_KREVENT } from 'config/constants';
import { processKrEvents } from './helpers/events';
import * as types from './types';
import * as constants from '../../constants/api';

import { getObjectiveProgressChartValues, parseObjectiveIDfromKeyresultID } from './helpers';

const cadenceState = {
  personal: {}, // personal objectives by owner id
  team: {}, // team objectives by teamId
  hierarchy: { canFetch: true }, // hierarchy of objectives that can be linked up to the mission
  links: {}, // objectiveId: {parent, children}
  ltperiod: null,
  company: { canFetch: true }, // company objectives,
  related: { canFetch: true }, // objectives in any way related to a user
};

export const initialState = {
  VERSION: 1.06,
  cadences: {},
  cadenceConfig: {
    ok: false,
    loading: false,
    error: false,
    canFetch: true,
    data: null,
  },
  actionlog: {},
  objectiveData: {},
  graphIdToLegacyMap: {},
};

function addPeriodsToState(state, stperiod) {
  if (!state.cadences[stperiod]) {
    // Deep copy the cadenceState object by converting to json and back
    state.cadences[stperiod] = JSON.parse(JSON.stringify(cadenceState));
  }
}

function getObjectiveCadenceParameters(state, objective) {
  let cfg = {};
  if (objective.stperiod) {
    cfg = state.cadenceConfig.data.stperiodconfigs[objective.stperiod];
  } else {
    cfg = state.cadenceConfig.data.periods[objective.ltperiod];
  }
  const start = moment(cfg.periodStart);
  const end = moment(`${cfg.periodEnd} 23:59:59`);
  const now = moment();

  let stage;
  let daysLeft = 0;
  if (now < start) {
    stage = 'PLANNING';
  } else if (now > end) {
    stage = 'HISTORY';
  } else {
    stage = 'LIVE';
    daysLeft = end.diff(now, 'days');
  }

  return {
    periodEnd: cfg.periodEnd,
    periodStart: cfg.periodStart,
    stage,
    daysLeft,
  };
}

function getApplicablePeriods(state, objective) {
  const shortTermPeriod = objective.stperiod;
  const periods = [];
  if (!shortTermPeriod) {
    // The objective was a strategic one, let's copy it to all Short term period slices
    for (const stperiod1 in state.cadenceConfig.data.periods[objective.ltperiod].stperiods) {
      periods.push(stperiod1);
    }
  } else {
    periods.push(shortTermPeriod);
  }
  return periods;
}

function linkToDomains(state, objective) {
  /* Store the link to the objective to all relevant domains in all the applicable periods */
  const periods = getApplicablePeriods(state, objective);

  for (const stperiod of periods) {
    addPeriodsToState(state, stperiod);

    if (objective.type === 'company') {
      if (!state.cadences[stperiod].company || !state.cadences[stperiod].company.ok) {
        state.cadences[stperiod].company = enrich({ fetchStatus: constants.PARTIAL, data: [] });
      }
      if (!state.cadences[stperiod].company.data.includes(objective.objectiveID)) {
        state.cadences[stperiod].company.data.push(objective.objectiveID);
      }
    } else if (objective.type === 'team') {
      if (
        !state.cadences[stperiod].team[objective.teamID] ||
        !state.cadences[stperiod].team[objective.teamID].ok
      ) {
        state.cadences[stperiod].team[objective.teamID] = enrich({
          fetchStatus: constants.PARTIAL,
          data: [],
        });
      }
      if (!state.cadences[stperiod].team[objective.teamID].data.includes(objective.objectiveID)) {
        state.cadences[stperiod].team[objective.teamID].data.push(objective.objectiveID);
      }
    } else if (objective.type === 'personal') {
      if (
        !state.cadences[stperiod].personal[objective.owner] ||
        !state.cadences[stperiod].personal[objective.owner].ok
      ) {
        state.cadences[stperiod].personal[objective.owner] = enrich({
          fetchStatus: constants.PARTIAL,
          data: [],
        });
      }

      if (
        !state.cadences[stperiod].personal[objective.owner].data.includes(objective.objectiveID)
      ) {
        state.cadences[stperiod].personal[objective.owner].data.push(objective.objectiveID);
      }
    }

    /* add objective to user relations */
    if (
      !state.cadences[stperiod].related[objective.owner] ||
      !state.cadences[stperiod].related[objective.owner].ok
    ) {
      state.cadences[stperiod].related[objective.owner] = enrich({
        fetchStatus: constants.PARTIAL,
        data: [],
      });
    }

    if (!state.cadences[stperiod].related[objective.owner].data.includes(objective.objectiveID)) {
      state.cadences[stperiod].related[objective.owner].data.push(objective.objectiveID);
    }
  }
}

function touchDomains(state, objective) {
  const periods = getApplicablePeriods(state, objective);

  for (const stperiod of periods) {
    if (objective.type === 'team') {
      if (!!state.cadences[stperiod] && !!state.cadences[stperiod].team[objective.teamID]) {
        state.cadences[stperiod].team[objective.teamID].hash = nanoid(10);
      }
    } else if (objective.type === 'company') {
      if (!!state.cadences[stperiod] && !!state.cadences[stperiod].company) {
        state.cadences[stperiod].company.hash = nanoid(10);
      }
    }

    if (!!!!state.cadences[stperiod] && !!state.cadences[stperiod].related[objective.owner]) {
      state.cadences[stperiod].related[objective.owner].hash = nanoid(10);
    }
    if (!!objective.keyresults) {
      for (const kr of objective.keyresults) {
        if (!!state.cadences[stperiod] && !!state.cadences[stperiod].related[kr.owner]) {
          state.cadences[stperiod].related[kr.owner].hash = nanoid(10);
        }
      }
    }
  }
}

function addObjectiveToState(state, objective, keepEvents = false) {
  /*
    Any time an Objective's data is added to the state it must be handled
    with this method.

    There's a lot of data normalization and manipulation that needs to happen
    for each objective to optimize the access patterns to that data.
  */
  if (!objective.keyresults) {
    objective.keyresults = [];
  }

  const objectiveLastModified =
    moment.utc(objective.lastModified) ||
    moment.utc(objective.createdOn) ||
    moment.utc('1900-01-01', 'YYYY-MM-DD');

  let lastModified = objectiveLastModified;

  // Ensure that we do not overwrite newer data:
  const previousKrData = {};
  if (
    !!state.objectiveData[objective.objectiveID] &&
    !!state.objectiveData[objective.objectiveID].ok
  ) {
    for (const kr of state.objectiveData[objective.objectiveID].data.keyresults) {
      previousKrData[kr.keyresultID] = kr;
    }
  }
  // Parse & store the graph_id of the Objective Node, needed for modern APIs
  objective.graph_id = `OBJ_${objective.objectiveID.split('_').pop()}`;

  const incomingKrsCount = get(objective, 'keyresults', []).length;
  const incomingKrIds = [];
  if (objective.keyresults && incomingKrsCount > 0) {
    for (let krIndex = 0; krIndex < incomingKrsCount; krIndex++) {
      const krID = objective.keyresults[krIndex].keyresultID;
      incomingKrIds.push(krID);
      const curLastModified = moment.utc(objective.keyresults[krIndex].lastModified);

      objective.keyresults[krIndex].objective_graph_id = objective.graph_id;
      objective.keyresults[krIndex].graph_id = `KR_${objective.keyresults[krIndex].keyresultID
        .split('_')
        .pop()}`;

      // Store the last modified value of krs as delta days from today
      objective.keyresults[krIndex].lastModifiedDays = moment.utc().diff(curLastModified, 'days');
      if (curLastModified > lastModified) {
        lastModified = curLastModified;
      }
      if ('todos' in objective.keyresults[krIndex]) {
        // ENG-836: add "ids" to todos to support future migration to graph
        objective.keyresults[krIndex].todos = objective.keyresults[krIndex].todos.map(
          (todo, index) => {
            if (!('id' in todo)) {
              todo.id = index;
            }
            return todo;
          },
        );
      }
      if (!!get(previousKrData, krID)) {
        // ENG-1280: Do not overwrite newer state objects
        if (moment.utc(previousKrData[krID].lastModified) > curLastModified) {
          objective.keyresults[krIndex] = previousKrData[krID];
        } else if (!!keepEvents) {
          objective.keyresults[krIndex].events = previousKrData[krID].events;
        }
      }
      processKrEvents(objective.keyresults[krIndex]);
    }
  }

  for (const previousKrId in previousKrData) {
    if (!incomingKrIds.includes(previousKrId)) {
      // The KR in the state was not included in the incoming KR list
      const krData = previousKrData[previousKrId];
      const krCreated = moment.utc(krData.createdOn);
      // the objective last modified value will be updated when a KR is deleted,
      // if the incoming data has a more recent timestamp, we can assume that the
      // KR in our state was deleted by another user, otherwise just add the KR
      // back - the assumption is that the KR was just created while the fetch
      // request was being processed by our backend

      // only allow this if the utc timestamp of missing KR is recent enough
      // enough (< 10 min)
      if (krCreated > objectiveLastModified && moment.utc().diff(krCreated, 'seconds') < 600) {
        objective.keyresults.push(krData);
      }
    }
  }
  // Handle compatibility with graph data model:
  state.graphIdToLegacyMap[objective.graph_id] = objective.objectiveID;
  if (objective.graph_id in state.objectiveData) {
    delete state.objectiveData[objective.graph_id];
  }

  objective.keyresults = orderBy(objective.keyresults, ['weight', 'keyresult'], ['desc', 'asc']);
  if (lastModified) {
    objective.lastModified = moment().diff(lastModified, 'days');
  }

  state.objectiveData[objective.objectiveID] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    data: objective,
  });

  const cadenceParams = getObjectiveCadenceParameters(state, objective);
  objective.stage = cadenceParams.stage;
  objective.daysLeft = cadenceParams.daysLeft;
  objective.periodEnd = cadenceParams.periodEnd;
  objective.periodStart = cadenceParams.periodStart;
  objective.eventdata = getObjectiveProgressChartValues(objective, true);
  objective.eventList = objective.keyresults.reduce(
    (val, kr) => val.concat(orderBy(kr.events, ['timestamp'], 'desc')),
    [],
  );
  touchDomains(state, objective);
  linkToDomains(state, objective);
}

export const rebuildHierarchy = slice => {
  /* rebuilds the hierarchy object based on data in the links object */
  if (!slice) return slice;
  let maxDepth = 0;

  function recurse(node, curDepth) {
    if (curDepth > maxDepth) {
      maxDepth = curDepth;
    }
    if (!!slice && !!slice.links && !!slice.links[node.id] && !!slice.links[node.id].children) {
      node.children = [];
      for (const child of slice.links[node.id].children) {
        const childObj = { id: child, children: [] };
        node.children.push(recurse(childObj, curDepth + 1));
      }
    }
    return node;
  }

  let hierarchy = { id: 'mission', type: 'MISSION' };
  const recursed = recurse(hierarchy, 0);
  hierarchy = enrich({
    data: recursed,
    fetchStatus: slice.hierarchy.fetchStatus || constants.PARTIAL,
  });
  hierarchy.data.maxDepth = maxDepth;
  slice.hierarchy = hierarchy;
  return slice;
};

function parseReceivedHierarchyForLinks(newstate, stperiod) {
  let maxDepth = 0;

  function recurse(node, curDepth, parentId) {
    /* update depth */
    if (curDepth > maxDepth) {
      maxDepth = curDepth;
    }

    /* store relations */
    newstate.cadences[stperiod].links[node.id] = enrich({
      fetchStatus: constants.OK,
      parent: parentId,
      children: [],
    });
    if (newstate.cadences[stperiod].links[parentId]) {
      newstate.cadences[stperiod].links[parentId].children.push(node.id);
    }

    /* recurse */
    if (node.children) {
      for (const child of node.children) {
        recurse(child, curDepth + 1, node.id);
      }
    }
  }

  recurse(newstate.cadences[stperiod].hierarchy.data, 0, null);
  newstate.cadences[stperiod].hierarchy.data.maxDepth = maxDepth;
}

function addFetchedPeriodsToState(state, action) {
  const newState = copyState(state);
  const stperiods = [];
  const stperiodconfigs = {};
  for (const ltperiod in action.payload.periods) {
    const ltperiodcfg = action.payload.periods[ltperiod];
    ltperiodcfg.displayName = ltperiodcfg.displayName || ltperiod;

    for (const stperiod in ltperiodcfg.stperiods) {
      stperiodconfigs[stperiod] = {
        ...ltperiodcfg.stperiods[stperiod],
        displayName: ltperiodcfg.stperiods[stperiod].displayName || stperiod,
        ltperiod,
        stperiod,
      };
    }
  }

  /* Sort the cadence periods by periodStart timestamp */
  const sortedPeriods = orderBy(stperiodconfigs, ['periodStart'], ['asc']);
  for (const stperiod2 of sortedPeriods) {
    stperiods.push(stperiod2.stperiod);
    addPeriodsToState(newState, stperiod2.stperiod);
  }

  newState.cadenceConfig = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 3600,
    data: { stperiods, stperiodconfigs, ...action.payload },
  });
  return newState;
}

function addFetchingPeriodToState(state) {
  const newState = copyState(state);

  newState.cadenceConfig = fetching(newState.cadenceConfig);

  return newState;
}

function addFailedPeriodToState(state) {
  const newState = copyState(state);
  newState.cadenceConfig = apiError(newState.cadenceConfig);
  return newState;
}

// NEW REDUCERS
// Implementing cadences support

/* company objectives */
function addFetchedCompanyObjectivesToState(state, action) {
  const newState = copyState(state);

  const { stperiod } = action.payload;

  addPeriodsToState(newState, stperiod);

  newState.cadences[stperiod].company = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    data: [],
    hash: nanoid(10),
  });

  for (const objective of action.payload.objectives) {
    addObjectiveToState(newState, objective);
  }

  return newState;
}

function addFetchingCompanyObjectivesToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload;
  addPeriodsToState(newState, stperiod);
  newState.cadences[stperiod].company = fetching(newState.cadences[stperiod].company);

  return newState;
}

function addFailedCompanyObjectivesToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload.request;
  addPeriodsToState(newState, stperiod);
  newState.cadences[stperiod].company = apiError(newState.cadences[stperiod].company);
  return newState;
}

/* team objectives */
function addFetchedTeamObjectivesToState(state, action) {
  const newState = copyState(state);

  const { stperiod } = action.payload;

  addPeriodsToState(newState, stperiod);
  const { teamId } = action.payload;

  newState.cadences[stperiod].team[teamId] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    data: [],
    hash: nanoid(10),
  });

  for (const objective of action.payload.objectives) {
    addObjectiveToState(newState, objective);
  }

  return newState;
}

function addFetchingTeamObjectivesToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload;
  const { teamId } = action.payload;
  addPeriodsToState(newState, stperiod);
  newState.cadences[stperiod].team[teamId] = fetching(newState.cadences[stperiod].team[teamId]);

  return newState;
}

function addFailedTeamObjectivesToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload.request;
  const { teamId } = action.payload.request;
  addPeriodsToState(newState, stperiod);
  newState.cadences[stperiod].team[teamId] = apiError(newState.cadences[stperiod].team[teamId]);
  return newState;
}

/* objectives linked to a person  */
function addFetchedRelatedObjectivesToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload;

  addPeriodsToState(newState, stperiod);
  const owner = action.payload.sub;

  newState.cadences[stperiod].related[owner] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    data: action.payload.objectives,
    hash: nanoid(10),
  });

  for (const objective of action.payload.objectivedata) {
    addObjectiveToState(newState, objective);
  }
  return newState;
}

function addFailedRelatedObjectivesToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload.request;
  const owner = action.payload.request.sub;
  addPeriodsToState(newState, stperiod);
  newState.cadences[stperiod].related[owner] = apiError(newState.cadences[stperiod].related[owner]);
  return newState;
}

function addFetchingRelatedObjectivesToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload;
  const owner = action.payload.sub;
  addPeriodsToState(newState, stperiod);
  newState.cadences[stperiod].related[owner] = fetching(newState.cadences[stperiod].related[owner]);

  return newState;
}

/* single objectives */
function addFetchingObjectivesToState(state, action) {
  const newState = copyState(state);

  for (const id of action.payload.objectiveIDs) {
    if (!!newState.objectiveData[id] && !!newState.objectiveData[id].ok) {
      newState.objectiveData[id] = enrich({
        ...newState.objectiveData[id],
        fetchStatus: constants.REFRESHING,
      });
    } else {
      newState.objectiveData[id] = enrich({
        ...newState.objectiveData[id],
        fetchStatus: constants.FETCHING,
      });
    }
  }
  return newState;
}

function addFetchedObjectivesToState(state, action) {
  const newState = copyState(state);
  for (const objective of action.payload.objectives) {
    addObjectiveToState(newState, objective);
  }

  for (const objectiveID of action.payload.notfound) {
    if (!newState.objectiveData[objectiveID] || !newState.objectiveData[objectiveID].ok) {
      newState.objectiveData[objectiveID] = enrich({ fetchStatus: constants.DELETED });
    }
  }

  return newState;
}

function addFailedObjectivesToState(state, action) {
  const newState = copyState(state);

  for (const id of action.payload.request.objectiveIDs) {
    if (!newState.objectiveData[id] || !newState.objectiveData[id].ok) {
      newState.objectiveData[id] = apiError(newState.objectiveData[id]);
    }
  }
  return newState;
}

/* objective modifications */

function updateObjectiveLinks(newState, objectiveID, parentID) {
  // Which periods does this objective exist in
  const parts = objectiveID.split('_');
  const parsedPeriods = parts.splice(-3, 2);
  const ltperiod = parsedPeriods[0];
  if (parts[0] === 'CO') {
    parentID = 'mission';
  }
  const isLongTerm = !parsedPeriods[1];
  const periods = [];

  for (const stperiod in newState.cadenceConfig.data.periods[ltperiod].stperiods) {
    periods.push(stperiod);
  }

  for (const stperiod of periods) {
    if (newState.cadences[stperiod] && (isLongTerm || parsedPeriods[1] === stperiod)) {
      // Store this objective
      const oldParentId =
        newState.cadences[stperiod].links[objectiveID] &&
        newState.cadences[stperiod].links[objectiveID].parent;
      if (!newState.cadences[stperiod].links[objectiveID]) {
        newState.cadences[stperiod].links[objectiveID] = enrich({
          fetchStatus: constants.OK,
          children: [],
        });
      } else {
        // Shallow copy object to ensure re-renders happen
        newState.cadences[stperiod].links[objectiveID] = {
          ...newState.cadences[stperiod].links[objectiveID],
        };
      }
      newState.cadences[stperiod].links[objectiveID].parent = parentID;

      // Remove the link from old parent
      if (
        oldParentId &&
        newState.cadences[stperiod].links[oldParentId] &&
        newState.cadences[stperiod].links[oldParentId].ok
      ) {
        newState.cadences[stperiod].links[oldParentId].children = newState.cadences[stperiod].links[
          oldParentId
        ].children.filter(child => child !== objectiveID);
      }

      // Add link to new parent
      if (!!parentID) {
        if (
          !newState.cadences[stperiod].links[parentID] ||
          isEmpty(newState.cadences[stperiod].links[parentID])
        ) {
          newState.cadences[stperiod].links[parentID] = enrich({
            fetchStatus: constants.PARTIAL,
            children: [],
          });
        }
        newState.cadences[stperiod].links[parentID].children.push(objectiveID);
      }
    }
    rebuildHierarchy(newState.cadences[stperiod]);
  }
}

function addUpdatedObjectiveToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'ok' };
  addObjectiveToState(newState, action.payload.objective, true);

  return newState;
}

function addCreatedObjectiveToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'ok', data: action.payload };
  addObjectiveToState(newState, action.payload.objective);
  updateObjectiveLinks(newState, action.payload.objective.objectiveID, action.payload.parent);
  return newState;
}

function addUpdatingKeyresultToState(state, action) {
  const objectiveID = parseObjectiveIDfromKeyresultID(action.payload.keyresultID);
  const newState = copyState(state);
  newState.objectiveData[objectiveID] = enrich({
    ...newState.objectiveData[objectiveID],
    lastFetched: Date.now(),
  });
  return newState;
}

function addUpdatedKeyresultToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'ok' };
  const objective = newState.objectiveData[action.payload.objectiveID].data;

  for (let i = 0; i < objective.keyresults.length; i++) {
    if (objective.keyresults[i].keyresultID === action.payload.keyresult.keyresultID) {
      objective.keyresults[i] = {
        ...objective.keyresults[i],
        ...action.payload.keyresult,
        lastModifiedDays: 0,
      };
      if ('todos' in objective.keyresults[i]) {
        // ENG-836: add "ids" to todos to support future migration to graph
        objective.keyresults[i].todos = objective.keyresults[i].todos.map((todo, index) => {
          if (!('id' in todo)) {
            todo.id = index;
          }
          return todo;
        });
      }
      if (!objective.keyresults[i].events) {
        objective.keyresults[i].events = [];
      }

      processKrEvents(objective.keyresults[i]);
    }
  }

  objective.keyresults = orderBy(objective.keyresults, ['weight', 'keyresult'], ['desc', 'asc']);
  touchDomains(newState, objective);

  objective.eventdata = getObjectiveProgressChartValues(objective, true);
  return newState;
}

function deleteObjectiveFromState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'ok' };
  const { objective } = action.payload;
  const shortTermPeriod = objective.stperiod;
  const periods = [];
  if (!shortTermPeriod) {
    // The objective was a strategic one, let's remove it from all the applicable periods
    for (const stperiod in newState.cadenceConfig.data.periods[objective.ltperiod].stperiods) {
      periods.push(stperiod);
    }
  } else {
    periods.push(shortTermPeriod);
  }

  newState.objectiveData[objective.objectiveID] = enrich({ fetchStatus: constants.DELETED });
  for (const stperiod3 of periods) {
    addPeriodsToState(newState, stperiod3);
    const cadenceSlice = newState.cadences[stperiod3];

    if (cadenceSlice.links[objective.objectiveID] && cadenceSlice.links[objective.objectiveID].ok) {
      const parentID = cadenceSlice.links[objective.objectiveID].parent;
      if (parentID) {
        if (cadenceSlice.links[parentID] && cadenceSlice.links[parentID].ok) {
          cadenceSlice.links[parentID].children = cadenceSlice.links[parentID].children.filter(
            id => id !== objective.objectiveID,
          );
        }
      }
      for (const childID of cadenceSlice.links[objective.objectiveID].children) {
        if (cadenceSlice.links[childID] && cadenceSlice.links[childID].ok) {
          cadenceSlice.links[childID].parent = null;
        }
      }
      delete cadenceSlice.links[objective.objectiveID];
    }

    if (objective.type === 'company' && cadenceSlice.company && cadenceSlice.company.data) {
      cadenceSlice.company.data = cadenceSlice.company.data.filter(
        objID => objID !== objective.objectiveID,
      );
    } else if (
      objective.type === 'team' &&
      cadenceSlice.team[objective.teamID] &&
      cadenceSlice.team[objective.teamID].data
    ) {
      cadenceSlice.team[objective.teamID].data = cadenceSlice.team[objective.teamID].data.filter(
        objID => objID !== objective.objectiveID,
      );
    } else if (
      objective.type === 'personal' &&
      cadenceSlice.personal[objective.owner] &&
      cadenceSlice.personal[objective.owner].data
    ) {
      cadenceSlice.personal[objective.owner].data = cadenceSlice.personal[
        objective.owner
      ].data.filter(objID => objID !== objective.objectiveID);
    }
    rebuildHierarchy(newState.cadences[stperiod3]);
    touchDomains(state, objective);
  }
  return newState;
}

function addCreatedKeyresultToState(state, action) {
  const newState = copyState(state);

  const { objectiveID } = action.payload;
  const keyresult = action.payload;
  const objective = newState.objectiveData[objectiveID].data;
  newState.actionlog[action.payload.requestID] = { result: 'ok', data: keyresult };
  objective.lastModified = 0;
  keyresult.lastModified = 0;
  keyresult.events = [];

  const objectiveGraphId = `OBJ_${objectiveID.split('_').pop()}`;
  keyresult.objective_graph_id = objectiveGraphId;
  keyresult.graph_id = `KR_${keyresult.keyresultID.split('_').pop()}`;

  if (!objective.keyresults) {
    objective.keyresults = [];
  }
  const d = new Date();
  const dStr = d.toISOString().replace('Z', ' ').replace('T', ' ');

  const predictedEvent = {
    type: EVENT_TYPE_KREVENT,
    isCreate: true,
    baseline: keyresult.baseline,
    committed: keyresult.committed,
    confidence: keyresult.confidence,
    krowner: keyresult.owner,
    owner: action.payload.sub,
    status: keyresult.status,
    target: keyresult.target,
    timestamp: dStr,
    prediction: true,
  };

  keyresult.events = [predictedEvent];

  objective.keyresults.push(keyresult);
  objective.keyresults = orderBy(objective.keyresults, ['weight', 'keyresult'], ['desc', 'asc']);

  objective.eventdata = getObjectiveProgressChartValues(objective, true);
  touchDomains(state, objective);
  return newState;
}

function removeDeletedKeyresultFromState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'ok' };

  const { objectiveID } = action.payload.keyresult;
  const objective = newState.objectiveData[objectiveID].data;

  objective.lastModified = 0;
  objective.keyresults = objective.keyresults.filter(
    kr => kr.keyresultID !== action.payload.keyresultID,
  );
  return newState;
}

function addUpdatedObjectiveLinksToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'ok' };
  updateObjectiveLinks(newState, action.payload.objectiveID, action.payload.parent);

  return newState;
}

/* objective relations */
function addFetchingLinksToState(state, action) {
  const newState = copyState(state);
  const { stperiod, objectiveID } = action.payload;
  addPeriodsToState(newState, stperiod);

  newState.cadences[stperiod].links[objectiveID] = fetching(
    newState.cadences[stperiod].links[objectiveID],
  );

  return newState;
}

function addFetchedLinksToState(state, action) {
  const newState = copyState(state);
  const { stperiod, objectiveID } = action.payload;
  addPeriodsToState(newState, stperiod);

  let parentID = null;
  const childIds = [];

  for (const objective of action.payload.children) {
    childIds.push(objective.objectiveID);
    addObjectiveToState(state, objective);
  }

  if (!!action.payload.parent && !!action.payload.parent.objectiveID) {
    parentID = action.payload.parent.objectiveID;
    addObjectiveToState(state, action.payload.parent);
  } else if (action.payload.parent === 'mission') {
    parentID = 'mission';
  }
  newState.cadences[stperiod].links[objectiveID] = enrich({
    fetchStatus: constants.OK,
    parent: parentID,
    children: childIds,
    lastFetched: Date.now(),
  });

  return newState;
}

function addFailedLinksToState(state, action) {
  const newState = copyState(state);
  const { stperiod, objectiveID } = action.payload.request;
  addPeriodsToState(newState, stperiod);

  newState.cadences[stperiod].links[objectiveID] = apiError(
    newState.cadences[stperiod].links[objectiveID],
  );

  return newState;
}

function addObjHierarchyToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload;
  addPeriodsToState(newState, stperiod);

  newState.cadences[stperiod].hierarchy = enrich({
    data: action.payload.hierarchy,
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
  });

  parseReceivedHierarchyForLinks(newState, stperiod);
  return newState;
}

function addFetchingHierarchyToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload;
  addPeriodsToState(newState, stperiod);

  newState.cadences[stperiod].hierarchy = fetching(newState.cadences[stperiod].hierarchy);
  return newState;
}

function addFailedHierarchyToState(state, action) {
  const newState = copyState(state);
  const { stperiod } = action.payload.request;
  addPeriodsToState(newState, stperiod);

  newState.cadences[stperiod].hierarchy = apiError(newState.cadences[stperiod].hierarchy);
  return newState;
}

function addCopiedObjectiveToState(state, action) {
  const newState = copyState(state);
  const { sourceID, objectiveID, requestID, targetStPeriod, targetLtPeriod } = action.payload;
  newState.actionlog[requestID] = { result: 'ok', data: action.payload };
  const sourceObjective = newState.objectiveData[sourceID].data;
  const objective = {
    ...sourceObjective,
    objectiveID,
    stperiod: targetStPeriod,
    ltperiod: targetLtPeriod,
  };
  linkToDomains(newState, objective);
  return newState;
}

function addApiErrorToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = { result: 'error' };
  if (!!action.payload.error && !!action.payload.error.error) {
    newState.actionlog[action.payload.requestID].message = action.payload.error.error;
  }
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), origAction) => {
  // Copy action to avoid mutation issues in tests
  const action = JSON.parse(JSON.stringify(origAction));
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    /* company objectives */
    case types.RECEIVED_COMPANY_OBJECTIVES:
      return addFetchedCompanyObjectivesToState(state, action);
    case types.GET_COMPANY_OBJECTIVES:
      return addFetchingCompanyObjectivesToState(state, action);
    case types.FAILED_COMPANY_OBJECTIVES:
      return addFailedCompanyObjectivesToState(state, action);

    /* team objectives */
    case types.RECEIVED_TEAM_OBJECTIVES:
      return addFetchedTeamObjectivesToState(state, action);
    case types.GET_TEAM_OBJECTIVES:
      return addFetchingTeamObjectivesToState(state, action);
    case types.FAILED_TEAM_OBJECTIVES:
      return addFailedTeamObjectivesToState(state, action);

    /* personal */
    case types.GET_RELATED_OBJECTIVES:
      return addFetchingRelatedObjectivesToState(state, action);
    case types.RECEIVED_RELATED_OBJECTIVES:
      return addFetchedRelatedObjectivesToState(state, action);
    case types.FAILED_RELATED_OBJECTIVES:
      return addFailedRelatedObjectivesToState(state, action);

    /* single objective */
    case types.GET_OBJECTIVE:
      return addFetchingObjectivesToState(state, action);
    case types.OBJECTIVE_FETCHED:
      return addFetchedObjectivesToState(state, action);
    case types.OBJECTIVE_GET_FAILED:
      return addFailedObjectivesToState(state, action);

    /* period configs */
    case types.PERIODS_FETCHED:
      return addFetchedPeriodsToState(state, action);
    case types.GET_PERIODS:
      return addFetchingPeriodToState(state);
    case types.PERIODS_FETCH_FAILED:
      return addFailedPeriodToState(state);

    /* objective relations */
    case types.GET_OBJECTIVE_LINKS:
      return addFetchingLinksToState(state, action);
    case types.RECEIVED_OBJECTIVE_LINKS:
      return addFetchedLinksToState(state, action);
    case types.FETCH_LINKS_FAILED:
      return addFailedLinksToState(state, action);

    /* KRS */
    case types.UPDATE_KEYRESULT:
      return addUpdatingKeyresultToState(state, action);
    case types.EDIT_KEYRESULT:
      return addUpdatingKeyresultToState(state, action);
    case types.UPDATE_KEYRESULT_TODOS:
      return addUpdatingKeyresultToState(state, action);
    case types.KEYRESULT_UPDATED:
      return addUpdatedKeyresultToState(state, action);
    case types.KEYRESULT_CREATED:
      return addCreatedKeyresultToState(state, action);
    case types.KEYRESULT_DELETED:
      return removeDeletedKeyresultFromState(state, action);

    case types.RECEIVED_HIERARCHY:
      return addObjHierarchyToState(state, action);
    case types.GET_HIERARCHY:
      return addFetchingHierarchyToState(state, action);
    case types.OBJECTIVE_HIERARCHY_FETCH_FAILED:
      return addFailedHierarchyToState(state, action);

    /* modifications */
    case types.OBJECTIVE_UPDATED:
      return addUpdatedObjectiveToState(state, action);
    case types.GRADED_OBJECTIVE:
      return addUpdatedObjectiveToState(state, action);
    case types.OBJECTIVE_CREATED:
      return addCreatedObjectiveToState(state, action);
    case types.OBJECTIVE_PARENT_UPDATED:
      return addUpdatedObjectiveLinksToState(state, action);
    case types.OBJECTIVE_DELETED:
      return deleteObjectiveFromState(state, action);

    /* Copying */
    case types.COPIED_TO_PERIOD:
      return addCopiedObjectiveToState(state, action);

    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action);

    case 'LOGOUT':
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
