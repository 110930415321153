import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PaginatedDataGrid from 'Components/Library/PaginatedDataGrid';
import PersonaSelector from 'Components/Library/PersonaSelector';
import { interlocksActions, interlocksSelectors } from 'state/ducks/interlocks/index';
import { INTERLOCK_STATUS_COLORS, NODE_TYPE_TEAM, NODE_TYPE_USER } from 'config/constants';
import StatusSelector from 'Components/Library/StatusSelector/index';
import { SORTING_ORDERS } from 'state/ducks/interlocks/types';
import InterlockRow from './InterlockRow';
import InterlocksTableHeader from './InterlocksTableHeader';

const FETCH_ACTION_MAP = {
  [NODE_TYPE_TEAM]: {
    UNRESOLVED: interlocksActions.fetchTeamInterlocks,
    RESOLVED: interlocksActions.fetchResolvedTeamInterlocks,
  },
  [NODE_TYPE_USER]: {
    UNRESOLVED: interlocksActions.fetchUserInterlocks,
    RESOLVED: interlocksActions.fetchResolvedUserInterlocks,
  },
};

const SELECTOR_MAP = {
  [NODE_TYPE_TEAM]: {
    UNRESOLVED: interlocksSelectors.selectTeamInterlocks,
    RESOLVED: interlocksSelectors.selectResolvedTeamInterlocks,
  },
  [NODE_TYPE_USER]: {
    UNRESOLVED: interlocksSelectors.selectUserInterlocks,
    RESOLVED: interlocksSelectors.selectResolvedUserInterlocks,
  },
};

const fetchActionCreator = (domain, previousData, statusFilter, force) => {
  if (!domain) {
    return null;
  }
  const fetchTypeKey = statusFilter === 'RESOLVED' ? 'RESOLVED' : 'UNRESOLVED';
  const payload = { force };

  if (previousData?.next_token && !force) {
    payload.next_token = previousData.next_token;
  }
  payload.id = domain.id;

  return FETCH_ACTION_MAP[domain.type][fetchTypeKey](payload);
};

function InterlocksGrid({ initialDomain, allowDomainChange }) {
  const { t } = useTranslation();
  const sub = useSelector(state => state.auth.userID);
  const [domain, setDomain] = useState(initialDomain || { type: NODE_TYPE_USER, id: sub });
  const [statusFilter, setStatusFilter] = useState('UNRESOLVED');
  const [sortColumn, setSortBy] = useState('default');
  const [sortOrder, setSortOrder] = useState(SORTING_ORDERS.asc);

  const selector = state => {
    const selectorStateKey = statusFilter === 'RESOLVED' ? 'RESOLVED' : 'UNRESOLVED';
    return SELECTOR_MAP[domain.type][selectorStateKey](state.main.interlocks, domain.id, {
      sortColumn,
      sortOrder,
    });
  };

  const statusOptions = [
    ...Object.keys(INTERLOCK_STATUS_COLORS).map(status => ({
      text: t(`interlocks.statusValueText.${status}`),
      option: status,
      color: INTERLOCK_STATUS_COLORS[status],
    })),
  ];
  return (
    <PaginatedDataGrid
      statusFilterNode={
        <StatusSelector
          variant="select"
          value={statusFilter}
          onSelect={setStatusFilter}
          options={statusOptions}
          name="status-selector"
        />
      }
      statusFilterValue={[statusFilter]}
      domain={domain}
      domainFilterNode={
        allowDomainChange ? (
          <PersonaSelector
            showRecent
            color="white"
            value={domain}
            onSelect={setDomain}
            name="paginated-data-grid-domain-selector"
            sx={{
              height: '24px !important',
              minHeight: '24px !important',
              borderRadius: '8px !important',
              maxWidth: '192px !important',
            }}
          />
        ) : null
      }
      fetchActionCreator={(previousData, force) =>
        fetchActionCreator(domain, previousData, statusFilter, force)
      }
      selector={state => selector(state)}
      RowElement={InterlockRow}
      columns={6}
      TableHeaderElement={InterlocksTableHeader}
      translationNameSpace="interlocks"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      setSortBy={setSortBy}
      setSortOrder={setSortOrder}
    />
  );
}

InterlocksGrid.propTypes = {
  initialDomain: PropTypes.object,
  allowDomainChange: PropTypes.bool,
};

InterlocksGrid.defaultProps = {
  allowDomainChange: true,
};

export default InterlocksGrid;
