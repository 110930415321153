import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TeamChip from 'Components/Library/TeamChip';
import UserChip from 'Components/Library/UserChip';
import PersonaSelector from 'Components/Library/PersonaSelector/index';
import { CONTRIBUTOR_TYPES, NODE_TYPE_USER } from 'config/constants';
import ContributorStatus from 'Components/Library/Contributors/ContributorStatus';
import { peopleSelectors } from 'state/ducks/people/index';

// make a list on contributors including only types TEAM and USER
export const makeContributorListWithTeamAndUser = data => {
  const contributors = [];
  if (data) {
    for (const contributor of data) {
      if (
        contributor.type === CONTRIBUTOR_TYPES.USER ||
        contributor.type === CONTRIBUTOR_TYPES.TEAM
      ) {
        contributors.push(contributor);
      }
    }
  }
  return contributors.sort((a, b) => (a.id > b.id ? 1 : -1));
};

// filter out if already a contributor
export const filterFunc = (filterObject, data, restrictedUserIds = []) => {
  if (data) {
    for (const contributor of data) {
      if (filterObject.id === contributor.id) {
        if (filterObject.type === contributor.type) {
          return false;
        }
        if (filterObject.type === NODE_TYPE_USER && contributor.type === 'CREATOR') {
          return false;
        }
        if (filterObject.type === NODE_TYPE_USER && contributor.type === 'OWNER') {
          return false;
        }
      }
    }
  }

  if (!!restrictedUserIds.length) {
    if (restrictedUserIds.indexOf(filterObject.id) > -1) {
      return false;
    }
  }
  return true;
};

function Contributors(props) {
  const {
    contributorsData,
    instanceData,
    canDelete,
    instanceName,
    actions,
    onSubmitSuccess,
    userID,
    managedTeamIds,
    restrictedUserIds,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(actions.fetchContributors({ id: instanceData?.data?.id, force: true }));
      mounted.current = true;
    } else {
      dispatch(actions.fetchContributors({ id: instanceData?.data?.id, force: false }));
    }
  });

  const contributors = makeContributorListWithTeamAndUser(contributorsData.data);

  const contributorsFilterFunc = filterObject =>
    filterFunc(filterObject, contributorsData.data, restrictedUserIds);

  const editContributor = (searchResult, action) => {
    let type = searchResult.type.toUpperCase();
    if (searchResult.type === NODE_TYPE_USER) {
      type = CONTRIBUTOR_TYPES.USER;
    }

    const payload = {
      id: instanceData.data.id,
      contributors: [{ data: { id: searchResult.id, type }, action }],
    };
    dispatch(actions.editContributors(payload));
    if (!!onSubmitSuccess) {
      onSubmitSuccess();
    }
  };

  const editContributorStatus = (contributor, status) => {
    let type = contributor.type.toUpperCase();
    if (contributor.type === NODE_TYPE_USER) {
      type = CONTRIBUTOR_TYPES.USER;
    }

    const payload = {
      id: instanceData.data.id,
      contributors: [{ data: { id: contributor.id, type, status }, action: 'UPDATE' }],
    };
    dispatch(actions.editContributorsStatus(payload));
    if (!!onSubmitSuccess) {
      onSubmitSuccess();
    }
  };

  const renderContributor = contributor => {
    let preparedContributorItem = null;
    let contributorItem = null;

    if (contributor.type === CONTRIBUTOR_TYPES.USER) {
      const canDeleteUser = canDelete || contributor.id === userID;
      contributorItem = (
        <UserChip
          color="white"
          sub={contributor.id}
          key={`${instanceName}-contributor-${contributor.id}`}
          statusComponent={
            canDeleteUser ? (
              <IconButton
                component="div"
                onClick={() => editContributor(contributor, 'DELETE')}
                name="delete-user-contributor"
                aria-label={t('general.delete')}
                sx={{
                  borderRadius: '10px',
                  p: 0.5,
                }}
              >
                <HighlightOffIcon sx={{ color: theme => theme.palette.primary[500] }} />
              </IconButton>
            ) : null
          }
        />
      );
    }
    if (contributor.type === CONTRIBUTOR_TYPES.TEAM) {
      const canDeleteTeam = canDelete || managedTeamIds.includes(contributor.id);
      contributorItem = (
        <TeamChip
          color="white"
          teamId={contributor.id}
          key={`${instanceName}-contributor-${contributor.id}`}
          statusComponent={
            canDeleteTeam ? (
              <IconButton
                component="div"
                onClick={() => editContributor(contributor, 'DELETE')}
                name="delete-team-contributor"
                aria-label={t('general.delete')}
                sx={{
                  borderRadius: '10px',
                  p: 0.5,
                }}
              >
                <HighlightOffIcon
                  sx={{ color: theme => theme.palette.primary[500], width: 15, height: 15 }}
                />
              </IconButton>
            ) : null
          }
        />
      );
    }

    if (contributor.status) {
      preparedContributorItem = (
        <Stack
          direction="row"
          sx={{ width: 1 }}
          key={`${instanceName}-contributor-${contributor.id}-status-${contributor.status}`}
        >
          <ContributorStatus
            value={contributor.status}
            onSelect={option => editContributorStatus(contributor, option)}
            contributorID={contributor.id}
            contributorType={contributor.type}
          />
          {contributorItem}
        </Stack>
      );
    } else {
      preparedContributorItem = contributorItem;
    }
    return preparedContributorItem;
  };

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
      {contributors.map(contributor => renderContributor(contributor))}
      {canDelete && (
        <PersonaSelector
          showRecent
          onSelect={searchResult => editContributor(searchResult, 'ADD')}
          filterFunc={contributorsFilterFunc}
          name={`${instanceName}-add-contributor`}
          ctaNode={
            <Button variant="text" color="secondary" name={`${instanceName}-add-contributor`}>
              {t(`${instanceName}.contributorInputLabel`)}
            </Button>
          }
        />
      )}
    </Stack>
  );
}

const mapStateToProps = (state, ownProps) => ({
  contributorsData: ownProps.selectContributors(
    state.main[ownProps.instanceName],
    ownProps.instanceData?.data?.id,
  ),
  userID: state.auth.userID,
  managedTeamIds: peopleSelectors.selectTeamsManagedBy(state.main.people, state.auth.userID),
});

Contributors.propTypes = {
  instanceName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types,react/forbid-prop-types
  actions: PropTypes.any.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  selectContributors: PropTypes.func.isRequired,
  instanceData: PropTypes.object.isRequired,
  contributorsData: PropTypes.object,
  canDelete: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
  userID: PropTypes.string,
  managedTeamIds: PropTypes.arrayOf(PropTypes.string),
  restrictedUserIds: PropTypes.arrayOf(PropTypes.string),
};
export default connect(mapStateToProps)(Contributors);
