import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import PersonasAvatar from 'Components/Common/PersonasAvatar';
import DataObjectChip from 'Components/Library/DataObjectChip';
import { peopleSelectors } from 'state/ducks/people';

function UserChip(props) {
  const {
    sub,
    color,
    actionable,
    onClick,
    statusComponent,
    actOnDownArrow,
    selected,
    name,
    id,
    ContextIcon,
    contextIconProps,
    variant,
    sx,
    tooltipText,
  } = props;

  const userName = useSelector(state => peopleSelectors.selectFullName(state.main.people, sub));

  return (
    <DataObjectChip
      color={color}
      text={userName}
      Icon={ContextIcon}
      contextIconProps={contextIconProps}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      icon={<PersonasAvatar sub={sub} size="xtiny" />}
      actionable={actionable}
      onClick={onClick}
      statusComponent={statusComponent}
      actOnDownArrow={actOnDownArrow}
      selected={selected}
      name={name}
      id={id}
      variant={variant}
      dataChipType="user"
      sx={sx}
      tooltipText={tooltipText}
    />
  );
}

UserChip.propTypes = {
  sub: PropTypes.string,
  contextIconProps: PropTypes.object,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  variant: PropTypes.oneOf(['small', 'normal']),
  ContextIcon: PropTypes.elementType,
  statusComponent: PropTypes.node,
  actionable: PropTypes.bool,
  onClick: PropTypes.func,
  actOnDownArrow: PropTypes.bool,
  selected: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  sx: PropTypes.object,
  tooltipText: PropTypes.string,
};

export default UserChip;
