import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TgLogo from 'Components/Library/TgLogo';
import { withNavigation } from 'withRouter';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    margin: 0,
    backgroundColor: theme.palette.background.app,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  loginBox: {
    margin: 'auto',
    width: '400px',
  },
});

class LoggedOut extends Component {
  componentDidMount() {
    let nextUrl = '/login';
    let timeOut = 4000;

    // check if a tenant switch was requested
    const nextTenant = localStorage.getItem('nextTenant');
    if (nextTenant) {
      localStorage.removeItem('nextTenant');
      nextUrl = `/login/${nextTenant}`;
      timeOut = 100;
    }

    this.refreshtimer = setTimeout(() => {
      this.props.navigate(nextUrl);
    }, timeOut);
  }

  componentWillUnmount() {
    clearTimeout(this.refreshtimer);
  }

  render() {
    const { t, classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.loginBox}>
            <Card elevation={24}>
              <Box sx={{ p: 4, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <TgLogo />
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 4,
                    mb: 0,
                    pb: 0,
                    alignSelf: 'stretch',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    id="LoggedOut-text"
                    sx={{ mb: 2 }}
                    color="text.disabled"
                  >
                    {t('general.loggedout')}
                  </Typography>

                  <Button
                    component={Link}
                    to="/login"
                    sx={{ width: '100%' }}
                    variant="contained"
                    color="secondary"
                    name="login"
                  >
                    {t('general.logincta')}
                  </Button>
                </Box>
              </Box>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

LoggedOut.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    wrapper: PropTypes.string,
    loginBox: PropTypes.string,
  }),
  t: PropTypes.func,
  navigate: PropTypes.func,
};

export default withNavigation(
  withTranslation()(withStyles(styles)(connect(mapStateToProps)(LoggedOut))),
);
