import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TgLogo from 'Components/Library/TgLogo';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import queryString from 'query-string';
import StandardInput from 'Components/Library/StandardInput';
import TgProgress from 'Components/Common/TgProgress';
import { withLocation, withParams } from 'withRouter';
import * as constants from '../state/constants/api';
import { validateEmail, validateToken, parseDomainFromEmail, debounce } from '../config/helpers';
import { tenantActions } from '../state/ducks/tenant';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    margin: 0,
    backgroundColor: theme.palette.background.app,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  loginBox: {
    margin: 'auto',
    width: '400px',
  },
  privacyLink: {
    display: 'block',
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(3),
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
});

const initialState = {
  email: '',
  emailError: null,
  emailInvalid: true,
  emailChangedAfterSubmit: false,
  autoFetch: false,
  submitted: false,
};

const checkStatus = () => {
  const authStorage = localStorage.getItem('persist:auth');
  if (!!authStorage) {
    const authObject = JSON.parse(authStorage);
    if (!!authObject.tokens) {
      const tokenObj = JSON.parse(authObject.tokens);
      if (!!tokenObj.access_token) {
        const now = new Date();
        if (parseInt(tokenObj.expires_in, 10) >= now.getTime()) {
          // eslint-disable-next-line no-console
          console.log('Detected positive auth status, redirecting');
          /* navigate() cannot be used here, we need to reload the page
          to ensure the state is rehydrated from localstorage to receive
          the latest info from the tab that did the login.
          */
          window.location.assign('/home');
        }
      }
    }
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeDebounced = debounce(this.onChangeDebounced, 1500);
    this.refreshtimer = setInterval(() => {
      checkStatus(this.props);
    }, 1000 * 2);
  }

  componentDidMount() {
    const qs =
      (this.props.location.state &&
        this.props.location.state.from &&
        queryString.parse(this.props.location.state.from.search)) ||
      queryString.parse(this.props.location.search);
    const receivedtenant = (this.props.params && this.props.params.tenant) || (!!qs && qs.tenant);
    if (!!receivedtenant) {
      this.setState({ autoFetch: true });
      this.props.dispatch(tenantActions.getTenant({ tenantId: receivedtenant }));
    }
  }

  componentWillUnmount() {
    clearTimeout(this.refreshtimer);
  }

  handleSubmit() {
    const { t } = this.props;
    if (!validateEmail(this.state.email) && !validateToken(this.state.email)) {
      this.setState({ emailError: t('general.pleaseEnterValidEmailAddressError') });
    } else {
      this.props.dispatch(
        tenantActions.getTenant({ identifier: parseDomainFromEmail(this.state.email) }),
      );
    }
    this.setState({
      emailChangedAfterSubmit: false,
      submitted: true,
    });
  }

  onKeyDown(event) {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    }
  }

  handleEmailChange = e => {
    e.target.value = e.target.value.trim();
    this.setState({ email: e.target.value, emailError: null, emailChangedAfterSubmit: true });
    if (validateEmail(e.target.value) || validateToken(e.target.value)) {
      this.setState({ emailInvalid: false, autoFetch: false });
    } else {
      this.setState({
        emailInvalid: true,
        autoFetch: false,
      });
    }
    e.persist();
    this.onChangeDebounced(e);
  };

  onChangeDebounced = e => {
    if (validateEmail(e.target.value) || validateToken(e.target.value)) {
      this.setState({ emailError: null });
    } else {
      this.setState({
        emailError: this.props.t('general.pleaseEnterValidEmailAddressError'),
      });
    }
  };

  redirectToIdp = tenantConfig => {
    const { from } = this.props.location.state || { from: { pathname: '/home' } };
    const { backdoor } = this.props;

    const search = queryString.parse(this.props.location.search);
    delete search.tenant;
    const idpName = backdoor ? 'COGNITO' : tenantConfig.idpName;
    const passedState = window.btoa(
      // eslint-disable-next-line no-restricted-globals
      JSON.stringify({ from, tenant: tenantConfig, search: queryString.stringify(search) }),
    );
    if (tenantConfig.loginType === 'cognito') {
      window.location.assign(
        `https://${tenantConfig.cognitoSubDomain}.auth.eu-west-1.amazoncognito.com/oauth2/authorize?redirect_uri=${constants.REDIRECT_URL}&response_type=code&client_id=${tenantConfig.clientId}&scope=email%20openid%20profile&state=${passedState}`,
      );
    } else {
      window.location.assign(
        `https://${tenantConfig.cognitoSubDomain}.auth.eu-west-1.amazoncognito.com/oauth2/authorize?state=${passedState}&identity_provider=${idpName}&redirect_uri=${constants.REDIRECT_URL}&response_type=code&client_id=${tenantConfig.clientId}&scope=email%20openid%20profile`,
      );
    }
  };

  render() {
    const { t, classes } = this.props;
    const { autoFetch, submitted } = this.state;
    let tenantConfig = null;
    if (
      this.props.tenant &&
      this.props.tenant.tenant &&
      this.props.tenant.tenant.cognitoSubDomain &&
      this.props.tenant.tenant.idpName &&
      this.props.tenant.tenant.tenantId &&
      this.props.tenant.tenant.loginType &&
      this.props.tenant.tenant.clientId
    ) {
      tenantConfig = {
        cognitoSubDomain: this.props.tenant.tenant.cognitoSubDomain,
        idpName: this.props.tenant.tenant.idpName,
        tenantId: this.props.tenant.tenant.tenantId,
        loginType: this.props.tenant.tenant.loginType,
        clientId: this.props.tenant.tenant.clientId,
      };
    } else if (
      !!this.props.auth &&
      !!this.props.auth.cognitoSubDomain &&
      !!this.props.auth.idpName &&
      !!this.props.auth.loginType &&
      !!this.props.auth.clientId &&
      !!this.props.auth.tenantID
    ) {
      tenantConfig = {
        cognitoSubDomain: this.props.auth.cognitoSubDomain,
        idpName: this.props.auth.idpName,
        tenantId: this.props.auth.tenantID,
        loginType: this.props.auth.loginType,
        clientId: this.props.auth.clientId,
      };
    }
    if (!!tenantConfig && (submitted || autoFetch)) {
      this.redirectToIdp(tenantConfig);
      return (
        <Stack justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
          <TgProgress center size={100} color="primary" />
        </Stack>
      );
    }
    const fetchError =
      this.props.tenant &&
      this.props.tenant.tenant &&
      this.props.tenant.tenant.error &&
      !this.state.emailChangedAfterSubmit;

    if (!!!fetchError && !!autoFetch) {
      return (
        <Stack justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
          <TgProgress center size={100} color="primary" />
        </Stack>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.loginBox}>
            <Card elevation={24}>
              <Box sx={{ p: 4, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <TgLogo />
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 4,
                    mb: 0,
                    pb: 0,
                    alignSelf: 'stretch',
                  }}
                >
                  <Typography variant="subtitle2" sx={{ mb: 2 }} color="text.disabled">
                    {t('general.loginScreenTitle')}
                  </Typography>
                  <StandardInput
                    autoFocus
                    label={t('general.email')}
                    placeholder={t('general.emailexample')}
                    name="work-email-input"
                    autoComplete="email"
                    value={this.state.email}
                    error={!!this.state.emailError}
                    helperText={this.state.emailError}
                    variant="filled"
                    onChange={this.handleEmailChange}
                    onKeyDown={this.onKeyDown}
                    fullWidth
                    style={{ color: grey[50] }}
                  />
                  <br />

                  {fetchError && (
                    <div name="tenant-error">
                      <Typography>{t('general.loginScreenTenantNotFoundError')}</Typography>
                      <Typography>
                        {t('general.loginScreenPleaseContactSalesCTA')}
                        <a
                          href="mailto:sales@tangible-growth.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          &nbsp;
                          {t('general.loginScreenSalesTeam')}
                        </a>
                      </Typography>
                    </div>
                  )}
                  <br />
                  <Button
                    name="work-email-submit"
                    onClick={() => this.handleSubmit()}
                    variant="contained"
                    color="secondary"
                    sx={{ width: '100%' }}
                    disabled={this.state.emailInvalid}
                  >
                    {t('general.continue')}
                  </Button>
                </Box>
              </Box>
            </Card>

            <Typography variant="caption" className={classes.privacyLink}>
              <a
                href="https://www.tangible-growth.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                name="link-to-privacy-policy"
              >
                {t('general.privacyPolicyLink')}
              </a>
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func,
  classes: PropTypes.exact({
    privacyLink: PropTypes.string,
    loginBox: PropTypes.string,
    wrapper: PropTypes.string,
    root: PropTypes.string,
  }),
  tenant: PropTypes.shape({
    tenant: PropTypes.exact({
      error: PropTypes.object,
      tenantId: PropTypes.string,
      idpName: PropTypes.string,
      cognitoSubDomain: PropTypes.string,
      loginType: PropTypes.string,
      clientId: PropTypes.string,
    }),
  }),
  auth: PropTypes.exact({
    cognitoSubDomain: PropTypes.string,
    idpName: PropTypes.string,
    loginType: PropTypes.string,
    clientId: PropTypes.string,
    tenantID: PropTypes.string,
    tokens: PropTypes.object,
  }),
  t: PropTypes.func,
  location: PropTypes.object,
  params: PropTypes.object,
  backdoor: PropTypes.bool,
};

const mapStateToProps = state => ({
  tenant: state.main.tenant,
  auth: state.main.auth,
});

export default withParams(
  withLocation(withTranslation()(connect(mapStateToProps)(withStyles(styles)(Login)))),
);
