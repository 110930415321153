import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';
import { peopleSelectors } from 'state/ducks/people';

import Form from 'Components/Library/Forms/';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import InlineEditInput from 'Components/Library/InlineEditInput';
import StandardDialog from 'Components/Library/StandardDialog';
import UserSelector from 'Components/Library/UserSelector';
import CreateDialogActions from 'Components/Library/CreateDialogActions';
import DomainSelector from 'Components/Features/Objectives/DomainSelector';
import PeriodSelector from 'Components/Features/Objectives/PeriodSelector';

import { OBJ_DESCRIPTION_MAX_LENGTH, OBJ_NAME_MAX_LENGTH } from 'config/constants';
import AiAssistCreateObjective from 'Components/Features/TgAI/AiPopover/AiAssistCreateObjective';
import FormContext from 'Components/Library/Forms/FormContext';
import { graphActions } from 'state/ducks/graph/index';

const styles = theme => ({
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(3),
    flex: 10,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    paddingBottom: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  formContainer: {
    marginBottom: theme.spacing(3),
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  savingIndicator: {
    marginTop: -1 * 32,
    marginRight: -1 * 32,
    marginLeft: -1 * 32,
    marginBottom: 32 - 6,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20 * -1 - 6,
      marginBottom: 20 + 2,
    },
  },
});

const schema = {
  type: 'object',
  properties: {
    objective: { type: 'string', minLength: 1, maxLength: OBJ_NAME_MAX_LENGTH },
    description: { type: 'string', maxLength: OBJ_DESCRIPTION_MAX_LENGTH },
    period: {
      type: 'object',
      properties: {
        stPeriodId: { anyOf: [{ type: 'string', minLength: 1 }, { type: 'null' }] },
        ltPeriodId: {
          type: 'string',
          minLength: 1,
        },
      },
    },
    domain: { type: 'object' },
  },
  required: ['objective', 'period'],
  additionalProperties: true,
};

const createReduxAction = params => {
  const { domain, period, ...rest } = params;
  let actionCreator = objectivesActions.createTeamObjective;
  if (domain.type === 'special') {
    if (domain.id === 'company') {
      actionCreator = objectivesActions.createCompanyObjective;
    } else if (domain.id === 'personal') {
      actionCreator = objectivesActions.createPersonObjective;
    }
  } else {
    rest.teamId = domain.id;
  }
  rest.ltperiod = period.ltPeriodId;
  if (!!period.stPeriodId) {
    rest.stperiod = period.stPeriodId;
  }
  return actionCreator(rest);
};

class AddObjectivePanel extends Component {
  constructor(props) {
    super(props);
    const initialDomain = {
      type: props.type === 'team' ? 'team' : 'special',
      id: props.type === 'team' ? props.teamId : props.type,
    };
    this.state = {
      initialDomain,
      selectedDomain: initialDomain,
      periodsParsed: false,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (!state.periodsParsed && !!nextProps.periodCfg.ok) {
      return {
        initialPeriod: {
          stPeriodId: nextProps.type === 'company' ? null : nextProps.stperiod,
          ltPeriodId: nextProps.ltperiod,
        },
        periodsParsed: true,
      };
    }
    return null;
  }

  onObjectiveCreated = result => {
    const objectiveID = get(result, 'data.objective.objectiveID');
    const objectiveApiId = get(result, 'data.objective.graph_id');

    if (!!this.props.onObjectiveCreated && objectiveID) {
      if (this.props.relatedItemID) {
        this.props.dispatch(
          graphActions.editRelations({
            changeset: [
              {
                action: 'ADD',
                from: this.props.relatedItemID,
                to: objectiveApiId,
                type: 'GENREL',
              },
            ],
          }),
        );
      }

      this.props.onObjectiveCreated(objectiveID);
    } else {
      this.props.onCancel();
    }
  };

  onValueChange = (changedField, values) => {
    if (changedField === 'domain') {
      if (values.domain.type === 'special' && values.domain.id === 'company') {
        if (!!values.period.stPeriodId) {
          values.period.stPeriodId = null;
        }
      }
      this.setState({ selectedDomain: values.domain });
    }
    return values;
  };

  periodFilterFunc = p => {
    const { selectedDomain } = this.state;
    if (selectedDomain.type === 'special' && selectedDomain.id === 'company' && !!p.stPeriodId) {
      return false;
    }
    return true;
  };

  ownerFilterFunc = sub => {
    const { isChangeManager, isManagerOf } = this.props;
    if (sub === this.props.sub) {
      return true;
    }
    if (!!isChangeManager) {
      return true; // can assign to anyone or create personal okrs to anyone
    }
    return isManagerOf(sub);
  };

  domainFilterFunc = domain => {
    const { isChangeManager, managedTeams, myTeams } = this.props;
    if (isChangeManager) {
      return true;
    }
    if (domain.type === 'special' && domain.id === 'personal') {
      return true;
    }
    if (domain.type === 'team') {
      return managedTeams.includes(domain.id) || myTeams.includes(domain.id);
    }
    return false;
  };

  render() {
    const { classes, onCancel, t } = this.props;
    return (
      <Form
        name="add-objective-form"
        schema={schema}
        initialValues={{
          domain: this.state.initialDomain,
          period: this.state.initialPeriod,
          owner: this.props.sub,
          objective: '',
        }}
        stateSlice="main.objectives"
        submitActionCreator={createReduxAction}
        onValueChange={this.onValueChange}
        onSubmitSuccess={this.onObjectiveCreated}
      >
        <StandardDialog
          open
          onBackdropClick={onCancel}
          preventBackDropCloseFunc={this.preventBackDropCloseFunc}
          size="small"
        >
          <LinearIndicator positioning="absolute" />
          <div className={classes.content} id="panel-add-objective-content">
            <div className={classes.formContainer}>
              <FormTextField
                fieldName="objective"
                render={fieldProps => (
                  <InlineEditInput
                    autoFocus
                    label={t('objectives.createNewObjectiveNameInputLabel')}
                    fullWidth
                    noPadding
                    hideFocus
                    multiline
                    variant="h4"
                    {...fieldProps}
                    sx={{ mb: 2 }}
                    InputProps={{
                      endAdornment: (
                        <FormContext.Consumer>
                          {formContext => (
                            <AiAssistCreateObjective
                              domain={formContext.values.domain}
                              value={fieldProps.value}
                              onChange={fieldProps.onChange}
                              period={formContext.values.period}
                            />
                          )}
                        </FormContext.Consumer>
                      ),
                    }}
                  />
                )}
              />
              <FormTextField
                fieldName="description"
                render={fieldProps => (
                  <InlineEditInput
                    label={t('objectives.createNewObjectiveDescriptionInputLabel')}
                    fullWidth
                    noPadding
                    hideFocus
                    multiline
                    variant="body1"
                    {...fieldProps}
                  />
                )}
              />
            </div>
            <CreateDialogActions>
              <FormSelectorField
                fieldName="domain"
                render={fieldProps => (
                  <DomainSelector filterFunc={this.domainFilterFunc} {...fieldProps} />
                )}
              />

              <FormSelectorField
                fieldName="owner"
                render={fieldProps => (
                  <UserSelector filterFunc={this.ownerFilterFunc} showRecent {...fieldProps} />
                )}
              />

              <FormSelectorField
                fieldName="period"
                render={fieldProps => (
                  <PeriodSelector filterFunc={this.periodFilterFunc} {...fieldProps} />
                )}
              />
            </CreateDialogActions>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onCancel} id="add-objective-form-cancel">
              {t('general.cancel')}
            </Button>
            <FormButton
              render={buttonProps => (
                <Button
                  id="add-objective-form-submit"
                  variant="contained"
                  color="secondary"
                  {...buttonProps}
                >
                  {t('objectives.createNewObjectiveButtonLabel')}
                </Button>
              )}
            />
          </div>
        </StandardDialog>
      </Form>
    );
  }
}

AddObjectivePanel.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    savingIndicator: PropTypes.string,
    formContainer: PropTypes.string,
    actionsContainer: PropTypes.string,
  }),
  handleCancel: PropTypes.func,
  t: PropTypes.func,
  type: PropTypes.string,
  teamId: PropTypes.string,
  sub: PropTypes.string,
  actionlog: PropTypes.object,
  ltperiod: PropTypes.string,
  stperiod: PropTypes.string,
  dispatch: PropTypes.func,
  onObjectiveCreated: PropTypes.func,
  onCancel: PropTypes.func,
  periodCfg: PropTypes.object,
  isManagerOf: PropTypes.func,
  isChangeManager: PropTypes.bool,
  managedTeams: PropTypes.array,
  myTeams: PropTypes.array,
  relatedItemID: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const slice = state.main.objectives;
  let selectedPeriod = ownProps.stperiod;
  if (selectedPeriod === 'current') {
    selectedPeriod = objectivesSelectors.selectCurrentPeriod(slice);
  }
  let ltperiod = objectivesSelectors.selectLtPeriodForStPeriod(slice, selectedPeriod);

  // getting current period if none is passed (in case of create related item)
  if (!ltperiod) {
    ltperiod = objectivesSelectors.selectLtPeriodForStPeriod(
      slice,
      objectivesSelectors.selectCurrentPeriod(slice),
    );
  }

  return {
    actionlog: slice.actionlog,
    ltperiod,
    periodCfg: objectivesSelectors.selectPeriodConfig(slice),
    isChangeManager: state.auth.isChangeManager,
    managedTeams: peopleSelectors.selectManagedTeams(
      state.main.people,
      state.auth.myTeams,
      state.auth.userID,
    ),
    myTeams: peopleSelectors
      .selectUsersMatrixTeams(state.main.people, state.auth.userID)
      .map(t => t.id)
      .concat(state.auth.teamID ? [state.auth.teamID] : []),
    isManagerOf: sub => peopleSelectors.isManagerOf(state.main.people, state.auth.userID, sub),
    sub: state.auth.userID,
    stperiod: selectedPeriod,
  };
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(AddObjectivePanel)));
