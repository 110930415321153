import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StandardInput from 'Components/Library/StandardInput';
import AiAnswer from 'Components/Features/TgAI/AiAnswer';
import AiLoadingIndicator from 'Components/Features/TgAI/AiLoadingIndicator';
import AiError from 'Components/Features/TgAI/AiError';
import { APIGW_URL } from 'state/constants/api';
import { getStore } from 'store';

function AiQuestionWrapper({
  onAnswerReceived,
  questionData,
  isHistoryEnabled,
  sessionId,
  setSessionId,
}) {
  const { t } = useTranslation();

  const { answer, question, isAnswered, id } = questionData;
  const [content, setContent] = useState(answer);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [prompt, setPrompt] = useState(question);
  const [timeoutId, setTimeoutId] = useState(null);

  const state = getStore().getState();

  const { tenantID } = state.auth;
  const accessToken = state.auth.tokens.access_token;

  const headers = { headers: { Authorization: `Bearer ${accessToken}` } };

  const startPolling = (sessionIdToUse, time) => {
    axios
      .get(`${APIGW_URL}/assistant/${tenantID}/answer`, {
        // eslint-disable-next-line camelcase
        params: { session_id: sessionIdToUse, time },
        ...headers,
      })
      .then(({ data }) => {
        if (data.result.status === 'IN_PROGRESS') {
          setTimeoutId(
            setTimeout(() => {
              startPolling(sessionIdToUse, time);
            }, 5000),
          );
        }
        if (data.result.status === 'COMPLETED') {
          clearInterval(timeoutId);
          setLoading(false);
          setContent(data.result.answer_data.answer);
          onAnswerReceived({
            answer: data.result.answer_data.answer,
            question: prompt,
            id,
          });
        }
      })
      .catch(e => {
        // Logging errors to help debugging for now:
        // eslint-disable-next-line no-console
        console.log('Caught: ', e);
        setError(true);
        setLoading(false);
      });
  };

  const dispatch = () => {
    setLoading(true);
    setError(false);

    const payload = {
      question: prompt,
      history: isHistoryEnabled,
    };

    if (!!sessionId && isHistoryEnabled) {
      payload.session_id = sessionId;
    }

    axios
      .post(`${APIGW_URL}/assistant/${tenantID}/kbquestion`, payload, headers)
      .then(({ data }) => {
        const receivedSessionId = data.result.answer_data.session_id;

        if (!sessionId) {
          setSessionId(receivedSessionId);
        }

        if (isHistoryEnabled) {
          startPolling(sessionId || receivedSessionId, data.result.answer_data.time);
        } else {
          setSessionId(receivedSessionId);
          startPolling(receivedSessionId, data.result.answer_data.time);
        }
      })
      .catch(e => {
        // Logging errors to help debugging for now:
        // eslint-disable-next-line no-console
        console.log('Caught: ', e);
        setError(true);
        setLoading(false);
      });
  };
  const onKeyDown = async event => {
    if (event.key === 'Enter' && !loading) {
      dispatch();
      return true;
    }

    return false;
  };

  return (
    <Stack direction="column" sx={{ p: 3, pt: 2 }}>
      <StandardInput
        autoFocus
        placeholder={t('ai.askQuestionInput')}
        variant="outlined"
        onKeyDown={onKeyDown}
        onChange={e => setPrompt(e.target.value)}
        value={prompt}
        disabled={loading || isAnswered}
        InputProps={
          isAnswered
            ? {}
            : {
                startAdornment: <SearchIcon fontSize="small" sx={{ mr: 1 }} color="action" />,
                endAdornment: (
                  <IconButton
                    size="small"
                    disabled={loading}
                    onClick={() => {
                      setPrompt('');
                      setContent(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
                autoComplete: 'off',
              }
        }
        sx={{
          '& .MuiOutlinedInput-root': { backgroundColor: 'background.paper' },
          width: isAnswered ? '385px' : '410px',
          mr: isAnswered ? 3 : 0,
        }}
      />
      {(content || loading || error) && (
        <Box
          sx={{
            mt: 2,
          }}
        >
          {content && !loading && <AiAnswer content={content} />}
          {loading && <AiLoadingIndicator title={t('ai.generatingAnswer')} />}
          {error && <AiError onRetry={dispatch} />}
        </Box>
      )}
    </Stack>
  );
}

AiQuestionWrapper.propTypes = {
  questionData: PropTypes.exact({
    id: PropTypes.string,
    isAnswered: PropTypes.bool,
    question: PropTypes.string,
    answer: PropTypes.string,
  }),
  onAnswerReceived: PropTypes.func,
  isHistoryEnabled: PropTypes.bool,
  setSessionId: PropTypes.func,
  sessionId: PropTypes.string,
};

export default AiQuestionWrapper;
